import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import CardsAvm from 'components/Software/Other_Platforms/AVM_Fritzphone/avmCards';
import YoutubVideo from 'components/Software/Other_Platforms/AVM_Fritzphone/VideoAVMFon';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/AVM_Fritzphone/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software for your INSTAR IP Camera",
  "path": "/Software/Other_Platforms/AVM_Fritzphone/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "Use your AVM Fritzphone to check your camera´s live video - use your AVM Fritzbox user interface to set it up.",
  "image": "./P_SearchThumb_AVM.png",
  "social": "/images/Search/P_SearchThumb_AVM.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_AVM_Fritzphone_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Fritzfon"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Camera Surveillance Software' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use your AVM Fritzphone to check your camera´s live video - use your AVM Fritzbox user interface to set it up.' image='/images/Search/P_SearchThumb_AVM.png' twitter='/images/Search/P_SearchThumb_AVM.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/AVM_Fritzphone/' locationFR='/fr/Software/Other_Platforms/AVM_Fritzphone/' crumbLabel="Fritzfon" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h2 {...{
      "id": "avm-fritzphone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#avm-fritzphone",
        "aria-label": "avm fritzphone permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AVM Fritzphone`}</h2>
    <YoutubVideo mdxType="YoutubVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`To display a live image of your INSTAR IP camera on the display of the FRITZ! Fon, you have to store the JPG path of the camera in the phone.`}</p>
    <CardsAvm mdxType="CardsAvm" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "the-jpg-path-of-your-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-jpg-path-of-your-instar-ip-camera",
        "aria-label": "the jpg path of your instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The JPG path of your INSTAR IP camera`}</h3>
    <ul>
      <li parentName="ul">{`The JPG path of your `}<a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/"
        }}>{`INSTAR WQHD 2k+ Camera`}</a>{` is:`}
        <ul parentName="li">
          <li parentName="ul">{`http://User:Password@Camera-Addresse/snap.cgi?chn=13`}</li>
          <li parentName="ul">{`E.g.http://admin:`}<a parentName="li" {...{
              "href": "mailto:instar@192.168.1.28"
            }}>{`instar@192.168.1.28`}</a>{`/snap.cgi?chn=13`}</li>
          <li parentName="ul">{`E.g. http://admin:`}<a parentName="li" {...{
              "href": "mailto:instar@abcd12.ddns3-instar.de"
            }}>{`instar@abcd12.ddns3-instar.de`}</a>{`/snap.cgi?chn=13`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The JPG path of your `}<a parentName="li" {...{
          "href": "/en/1080p_Series_CGI_List/"
        }}>{`INSTAR Full HD Camera`}</a>{` is:`}
        <ul parentName="li">
          <li parentName="ul">{`http://User:Password@Camera-Addresse/tmpfs/auto.jpg`}</li>
          <li parentName="ul">{`E.g.http://admin:`}<a parentName="li" {...{
              "href": "mailto:instar@192.168.1.28"
            }}>{`instar@192.168.1.28`}</a>{`/tmpfs/auto.jpg`}</li>
          <li parentName="ul">{`E.g. http://admin:`}<a parentName="li" {...{
              "href": "mailto:instar@abcd12.ddns3-instar.de"
            }}>{`instar@abcd12.ddns3-instar.de`}</a>{`/tmpfs/auto.jpg`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The JPG path of your `}<a parentName="li" {...{
          "href": "/en/720p_Series_CGI_List/"
        }}>{`INSTAR HD Camera`}</a>{` is:`}
        <ul parentName="li">
          <li parentName="ul">{`http://User:Password@Camera-Addresse/tmpfs/auto.jpg`}</li>
          <li parentName="ul">{`E.g.http://admin:`}<a parentName="li" {...{
              "href": "mailto:instar@192.168.1.28"
            }}>{`instar@192.168.1.28`}</a>{`/tmpfs/auto.jpg`}</li>
          <li parentName="ul">{`E.g. http://admin:`}<a parentName="li" {...{
              "href": "mailto:instar@abcd12.ddns3-instar.de"
            }}>{`instar@abcd12.ddns3-instar.de`}</a>{`/tmpfs/auto.jpg`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The JPG path of your `}<a parentName="li" {...{
          "href": "/en/Advanced_User/CGI_Commands/VGA_Series_CGI_List/"
        }}>{`INSTAR VGA Camera`}</a>{` is:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://192.168.1.28/snapshot.cgi?user=Benutzer&pwd=Kennwort"
            }}>{`http://192.168.1.28/snapshot.cgi?user=Benutzer&pwd=Kennwort`}</a></li>
          <li parentName="ul">{`E.g. `}<a parentName="li" {...{
              "href": "http://192.168.1.28/snapshot.cgi?user=admin&pwd=instar"
            }}>{`http://192.168.1.28/snapshot.cgi?user=admin&pwd=instar`}</a></li>
          <li parentName="ul">{`E.g. `}<a parentName="li" {...{
              "href": "http://abcd12.ddns3-instar.de/snapshot.cgi?user=admin&pwd=instar"
            }}>{`http://abcd12.ddns3-instar.de/snapshot.cgi?user=admin&pwd=instar`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`Attention: The FRITZ! Fon does not automatically encode special characters in user name or password, that is, You must replace the corresponding characters in the path with the% representation. Which signs are affected can be found at `}<a href="https://en.wikipedia.org/wiki/URL-Encoding" target="_blank" rel="noopener noreferrer">{`this Link`}</a>{`.`}</p>
    <p>{`In some cases, it may be necessary to attach a number combination to the JPG path in an HD camera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.28/tmpfs/snap.jpg?usr=admin&pwd=instar&1461568423210`}</code></pre></div>
    <h3 {...{
      "id": "adding-the-live-image-via-the-web-interface-of-the-fritzbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-the-live-image-via-the-web-interface-of-the-fritzbox",
        "aria-label": "adding the live image via the web interface of the fritzbox permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the live image via the web interface of the Fritzbox`}</h3>
    <p>{`To display a live image of your INSTAR IP camera on the display of the FRITZ! Fon, you have to store the JPG path of the camera in the phone. In the example (above), we did this directly over the phone. Since the latest software version of the AVM Fritzbox, this is now also possible directly over the web interface of the router - and there much more comfortable to set up.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "692px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/91e7e/Fritzbox-Fritzphone_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB00lEQVQoz22Ry2oUQRSGC0RQcetWFz6JPoJvILhzYSIy7twI7sSlSMgYswiIoBLRjUQkEZLMDYOGiTo9mUxn0unprr5U162r6lRJp0fNwsPPWXyLU+d8hS7fWn58+/rFmy/PzG9dfbRz5eHXSw96aG5zlvmtKnMnuVORC/dbZ+9tn2+0zjW20Y1nnbsLr649aTdW91e6+OmX42YLL/XIYpc0q+TPu3mzFS/3spUdstTNF9r5YqfqL3oEbQyyTyOzcSAnKf0e8M0RWx+yVIBzzlpXdecGWHcmqh3oVM5IXYiR1CjhnOOCD2I5Ss3uUTFJaJJTIpTUliv4GRW7E9LxokNMhbZCW65taSwilGkD2oCUfDYWtNHKaFWPt87mnHDGpmEwDadSCvfvZSkzyiyYjPI1j7/r0zd9ERTV2gqcMraQ8NljH/bI62/pEJc1L7VVGhDHOPI8p1XGxZrH3vfp6p7wCZQGWAm8BCJhfZ9//MXe9tkwMcpYpqq1NTjEwlAdjJ1SUkk/Tn6MYx8zbR1lvGDcgDVgvYBgag5xMRwfHYeRLBXYSicKwiAhGeMFZ+TkRKh1WvtXqpNSqUqMBqNPc4QjH0/9JPZpkdS+7J9POl3/hb8BwpMPJnsOkdsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/e4706/Fritzbox-Fritzphone_01.avif 230w", "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/d1af7/Fritzbox-Fritzphone_01.avif 460w", "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/3f79d/Fritzbox-Fritzphone_01.avif 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/a0b58/Fritzbox-Fritzphone_01.webp 230w", "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/bc10c/Fritzbox-Fritzphone_01.webp 460w", "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/f686e/Fritzbox-Fritzphone_01.webp 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/81c8e/Fritzbox-Fritzphone_01.png 230w", "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/08a84/Fritzbox-Fritzphone_01.png 460w", "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/91e7e/Fritzbox-Fritzphone_01.png 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb5c0a4cdf85b989f5beca4ea31059e6/91e7e/Fritzbox-Fritzphone_01.png",
              "alt": "AVM Fritzphone C4 for your INSTAR IP Camera",
              "title": "AVM Fritzphone C4 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig1.`}</strong>{` Open the DECT menu and select Internet Services.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "692px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f16008579f1f8476a2246cdac72a1ed/91e7e/Fritzbox-Fritzphone_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABsklEQVQoz4XMz2sTQRwF8LkIqaCnHrx6sxQqtMY/wJP/gx68+BeIsRS91VNaULT24k0PCoKgaFHBq0kEpRpj026sm23G3SQ7P3d2dme+sysheilYHx/e7T106sqjlasXj116MnOtcXZte6G+fXr180ytefzGH5Vaq1JrVq43pmZvfTy53Jy92Tqx3ECrzz89e/X09ouvlx9359d3qnd3FtY7Z+qduXpnfq0zV/+2eKe9dM87vxlUH/Srm/2lDf/chr9437/w8CeCLMmdK8tSJLKL2S6mvZD1It4LmfeL/QhpSMYHMffHqh8rf5yEwoyUGyo3lDmKKU+1BlekaSIYlZzpVIE1hYPC2QIsADiwDswUgLHWAlhjMsRkYsFZ57IsFUKmWlPK4phQxrgQjHPGuc6y3JgpAFMW1mTKpgK5XIGWZeHA6PLIFMWk+8y+9dQ7L20FCdr6zl63R1u7ag+PJA0pjRgJJ2h0CKWRYNH+AH/oBo1u8GU/QC/b5L2XvPH0Hh4KckDiAf03Eg8kxUbiXGLNMZIkzOS4hESJIY0HjOCj0RiTv1AUBVEUjEaT1/8uD/kN84D8tcpcxKkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f16008579f1f8476a2246cdac72a1ed/e4706/Fritzbox-Fritzphone_02.avif 230w", "/en/static/2f16008579f1f8476a2246cdac72a1ed/d1af7/Fritzbox-Fritzphone_02.avif 460w", "/en/static/2f16008579f1f8476a2246cdac72a1ed/3f79d/Fritzbox-Fritzphone_02.avif 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f16008579f1f8476a2246cdac72a1ed/a0b58/Fritzbox-Fritzphone_02.webp 230w", "/en/static/2f16008579f1f8476a2246cdac72a1ed/bc10c/Fritzbox-Fritzphone_02.webp 460w", "/en/static/2f16008579f1f8476a2246cdac72a1ed/f686e/Fritzbox-Fritzphone_02.webp 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f16008579f1f8476a2246cdac72a1ed/81c8e/Fritzbox-Fritzphone_02.png 230w", "/en/static/2f16008579f1f8476a2246cdac72a1ed/08a84/Fritzbox-Fritzphone_02.png 460w", "/en/static/2f16008579f1f8476a2246cdac72a1ed/91e7e/Fritzbox-Fritzphone_02.png 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f16008579f1f8476a2246cdac72a1ed/91e7e/Fritzbox-Fritzphone_02.png",
              "alt": "AVM Fritzphone C4 for your INSTAR IP Camera",
              "title": "AVM Fritzphone C4 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig2.`}</strong>{` Go to the tab live image and select a new picture to add.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "692px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/91e7e/Fritzbox-Fritzphone_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABrUlEQVQoz5XPy4oTQRTG8RLEB5gHEHwXH8KNO7cjirhR3HhZis5KxMUsdRNERESdlZIYFIMBGR1QGDLpTneqq6tv1V19/UtiAlmMtwM/TlVxONQnts7vcu3CWcS5x4jtPmdujTh9c8TW9Y/Lu7j4B9t9Tl0dIi4NOHnlPScuDxB3nn6i9+IJN3pjbr865NEHzcOh4t47eaz7S8GqS+6+lez0FTsDzYNhiChNQsuqWkNnM9oiha4EmsUjUK/O9YZqo3frDQilI/Iip247MmOYByFBGGEKiy8DXM8nUBoZhCSZIbcltqp/KSuqpqVqoWo6yrpF6Dihbtola3OkDIiiGGstU8dhMpngeT6e5zGbeUgpieOEOI4JtaYwGTSWriqWxCJiZeJltK62ZCYnzTKKwlJVNWVZcVytQ3ppw3hm2Z+XHMgSsXcQ8WZfsvfd8MNTFJkmTdbC30qSEGs0nw99eqMpz8cOr7+4iN5Y8fIg5dnXnG9TnyQ8IgymaPV3i7lMO5SJi41dithBJMqlTBW0OSaZo5VDFLr/TCuXcIOQ8yPm/gQpFz9zVkP/t3TTT1d1zgt6NLfwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24e69f1b6d3159ad1a6cbd6146151f16/e4706/Fritzbox-Fritzphone_03.avif 230w", "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/d1af7/Fritzbox-Fritzphone_03.avif 460w", "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/3f79d/Fritzbox-Fritzphone_03.avif 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24e69f1b6d3159ad1a6cbd6146151f16/a0b58/Fritzbox-Fritzphone_03.webp 230w", "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/bc10c/Fritzbox-Fritzphone_03.webp 460w", "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/f686e/Fritzbox-Fritzphone_03.webp 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24e69f1b6d3159ad1a6cbd6146151f16/81c8e/Fritzbox-Fritzphone_03.png 230w", "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/08a84/Fritzbox-Fritzphone_03.png 460w", "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/91e7e/Fritzbox-Fritzphone_03.png 692w"],
              "sizes": "(max-width: 692px) 100vw, 692px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24e69f1b6d3159ad1a6cbd6146151f16/91e7e/Fritzbox-Fritzphone_03.png",
              "alt": "AVM Fritzphone C4 for your INSTAR IP Camera",
              "title": "AVM Fritzphone C4 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig3.`}</strong>{` Enter a name for the image and enter the path to the JPG path of your camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      